.content {
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 400px;
  border-radius: 7px;
  padding: 40px;
}

.title {
  display: block;
  width: 100%;
  margin: 0 auto;
  color: rgb(72, 72, 72);
  font-weight: 300;
  margin-bottom: 20px;
}

.caption {
  font-weight: 250;
  display: block;
  color: rgb(84, 84, 84);
  margin-bottom: 20px;
}
