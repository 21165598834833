.row {
  cursor: pointer;
  transition: 0.1s;
}

.lastCell {
  padding: 0 !important;
  border: 0 !important;
}

.loadingWrapper {
  height: 70px;
  padding: 15px;
}

:global .MuiTableRow-root.Mui-selected {
  background-color: #f2f8f7 !important;
}
