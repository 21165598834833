.nav {
  width: 150px;
  flex-shrink: 0;
  background-color: rgb(245, 245, 245);
  height: 100%;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  height: 97px;
  max-width: 100%;

  padding-top: 8px;
  padding-left: 6px;
}
.link {
  color: inherit;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;

  li {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &:hover li {
    background-color: rgba(rgb(0, 0, 0), 0.1);
  }
}

.active {
  li {
    background-color: rgba(rgb(255, 255, 255), 0.15) !important;
    color: #08857c;
  }
}

.footerLogo {
  display: block;
  max-width: 100%;
}
