.header {
  background-color: rgb(255, 255, 255);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
  font-weight: 500;
}
.user {
  display: flex;
  align-items: center;
  text-align: right;
  font-size: 10px;
  cursor: pointer;
}

.organization {
  font-size: 14px;
  font-weight: 100;
  opacity: 0.8;
  color: black;
}

.tab {
  text-decoration: inherit;
  display: inline-block;
  color: rgba(0, 0, 0, 0.496);
  text-align: left;
  padding-bottom: 12px;
  margin-right: 20px;
  border-bottom: 3.5px solid transparent;
  transition: color 0.55s, border-bottom-color 0.55s !important;

  &:hover {
    color: #008080;
    border-bottom-color: #008080;
  }
}

.active {
  border-bottom-color: #008080;
  color: #008080 !important;
}

.logout {
  z-index: 100;
  position: relative;
  top: 6px !important;
  left: 17px !important;
}

.title {
  font-weight: 300;
}

.avatar:global(.MuiAvatar-colorDefault) {
  margin-right: 0.75rem;
}

.readOnly {
  font-size: 12px;
  padding: 6px 16px;
  color: #8e8e8e;

  &:focus {
    outline: 0;
    border: 0;
  }
}

.name {
  font-size: 14px;
}
