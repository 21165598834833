#hubspot-container {
  z-index: 9998;
  position: fixed;
  right: 0;
  bottom: 0;
  height: 42rem;
  width: 24rem;
  border-radius: 10px;
}

#hubspot-chat-widget-id {
  height: calc(100% - 6rem);
  width: calc(100% - 1rem);
  position: absolute;
  bottom: 6rem;
  right: 1rem;
}

#hubspot-conversations-inline-parent {
  position: relative;
  width: 100%;
  height: 100%;
  /* height: calc(100% - 3rem); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
    /* Horizontal offset, Vertical offset, Blur radius, Color */ 0 6px 20px rgba(0, 0, 0, 0.1); /* Second shadow for more depth */
}

#hubspot-conversations-inline-iframe {
  height: 100%;
  width: 100%;
  /* height: calc(100% - 3rem); */
  border: none; /* Removes the border */
  border-radius: 10px; /* Apply rounded corners to the iframe as well */
}
