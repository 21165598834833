.resetSuccessTitle {
  display: block;
  font-weight: 300;
  margin: 0 auto;
  color: rgb(72, 72, 72);
  margin-bottom: 20px;
  font-size: 20px;
  word-wrap: break-word;
}

.resetTitle {
  display: block;
  width: 100%;
  margin: 0 auto;
  font-weight: 300;
  color: rgb(72, 72, 72);
  margin-bottom: 20px;
  margin-right: 30px;
}

.caption {
  font-weight: 250;
  display: block;
  color: rgb(84, 84, 84);
  line-height: 1.4em;
  margin-bottom: 20px;
}

.label {
  color: rgb(119, 119, 119);
}
