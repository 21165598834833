.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.suspendButton {
  color: #b95a27 !important;
}
