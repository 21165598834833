.rhap_container {
  background-color: #65a59b;
  border-radius: 10px;
}
.rhap_main-controls-button,
.rhap_volume-button,
.rhap_repeat-button,
.rhap_time,
.rhap_header {
  color: white;
}
.rhap_progress-indicator,
.rhap_volume-indicator {
  background: white;
}
.rhap_progress-filled {
  background-color: white;
}

.rhap_download-progress {
  background: #acd8c4;
}
