.title {
  display: block;
  width: 100%;
  margin: 0 auto;
  font-weight: 400;
  color: rgb(72, 72, 72);
  margin-bottom: 20px;
}

.caption {
  font-weight: 300;
  display: block;
  color: rgb(84, 84, 84);
  line-height: 1.4em;
  margin-bottom: 20px;
}

.backToSignIn {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.54);
}

.logo {
  display: block;
  width: 100px;
  margin: auto;
}

.content {
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 400px;
  border-radius: 7px;
  padding: 75px 40px 75px 40px;
}
