.list {
  height: 400px;
  overflow-y: auto;
  @media (max-width: 780px) {
    order: 2;
  }
}

.listItem {
  height: 90px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 0px;
  font-size: 12px;

  &:nth-child(even) {
    background-color: #eee;
  }

  &:last-child {
    margin-bottom: 310px;
  }
}

.brushAbsoluteContainerTop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;
  z-index: 10;
}

.brushAbsoluteContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;
}

.brush > rect:first-child {
  opacity: 0;
}

.brushRelativeContainer {
  position: relative;
  height: 100px;
  margin-left: 70px;
}

.headerPaper:global(.MuiPaper-root) {
  background-color: var(--green);
  color: #fff;
}

.headerItem {
  text-align: center;
  position: relative;
  font-weight: 300;
}

.headerItemBg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  fill: #fff;
}

.headerItemValue {
  font-size: 36px;
}

.brushDates {
  display: flex;
  justify-content: space-between;
  margin-left: 70px;
}

:global(.recharts-surface) {
  overflow: visible;
}
