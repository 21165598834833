.content {
  height: 100vh;
  display: flex;
}

.main {
  flex-grow: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
  background-color: white;
}
