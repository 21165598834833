:root {
  --green: #008080;
  --lightGreen: #82b25a;
  --completedGreen: #65c2b8;
  --white: #fff;
  --gray: #efefef;
  --gray-50: #f9fafb;
  --gray-200: rgb(229 231 235);
  --gray-500: #9ca3af;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: rgb(31 41 55);
  --teal: #65c2b8;
  --beige: #f8e496;
  --red: #f50057;
  --blue: #0091bd;
  --black: rgba(0, 0, 0, 0.87);
  --orange: #e76f51;
  --yellow: #ff9933;
  --dark-blue: #3784ad;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.MuiPaper-root.unyte-success-class {
  background-color: #008080;
  color: #fff;
}

.MuiPaper-root.unyte-error-class {
  background-color: #f50057;
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

// COLORS!! inspired by: https://tailwindcss.com/docs/text-color/#app
.text-white {
  color: #fff !important;
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray {
  color: #2d3748;
}

.text-link {
  color: #616161;
}

.text-green {
  color: #008080;
}

.text-orange {
  color: #ff6600;
}

.text-center {
  text-align: center;
}

.text-blue {
  color: var(--blue);
}

.text-left {
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

.fill-current {
  fill: currentColor;
}

.border-0 {
  border-width: 0px !important;
}

.bg-orange {
  background-color: var(--beige);
}

.rounded {
  border-radius: 0.25rem;
}

// font weights
.font-thin {
  font-weight: 100 !important;
}
.font-extralight {
  font-weight: 200 !important;
}
.font-light {
  font-weight: 300 !important;
}
.font-normal {
  font-weight: 400 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-extrabold {
  font-weight: 800 !important;
}
.font-black {
  font-weight: 900 !important;
}

// text green
.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

// backgrounds
.bg-table-highlighted {
  background-color: #f2f8f7;
}

// overflows
.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.icon-green {
  color: #008080;
}

// this is for printing
.print-h1 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.625rem;
  line-height: 1;
}
.print-h2 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  line-height: 1;
}
.print-h3 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  line-height: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.print-h4 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1;
}
