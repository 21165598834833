@for $i from 0 through 15 {
  .mt-#{$i} {
    margin-top: #{0.25rem * $i} !important;
  }
  .mb-#{$i} {
    margin-bottom: #{0.25rem * $i} !important;
  }
  .ml-#{$i} {
    margin-left: #{0.25rem * $i} !important;
  }
  .mr-#{$i} {
    margin-right: #{0.25rem * $i} !important;
  }
  .m-#{$i} {
    margin: #{0.25rem * $i} !important;
  }
  .mx-#{$i} {
    margin-left: #{0.25rem * $i} !important;
    margin-right: #{0.25rem * $i} !important;
  }
  .my-#{$i} {
    margin-top: #{0.25rem * $i} !important;
    margin-bottom: #{0.25rem * $i} !important;
  }
}

@for $i from 0 through 15 {
  .pt-#{$i} {
    padding-top: #{0.25rem * $i} !important;
  }
  .pb-#{$i} {
    padding-bottom: #{0.25rem * $i} !important;
  }
  .pl-#{$i} {
    padding-left: #{0.25rem * $i} !important;
  }
  .pr-#{$i} {
    padding-right: #{0.25rem * $i} !important;
  }
  .p-#{$i} {
    padding: #{0.25rem * $i} !important;
  }
  .px-#{$i} {
    padding-left: #{0.25rem * $i} !important;
    padding-right: #{0.25rem * $i} !important;
  }
  .py-#{$i} {
    padding-top: #{0.25rem * $i} !important;
    padding-bottom: #{0.25rem * $i} !important;
  }
}

@for $i from 0 through 30 {
  .h-#{$i} {
    height: #{0.25rem * $i};
  }
}

@for $i from 0 through 30 {
  .w-#{$i} {
    width: #{0.25rem * $i};
  }
}

.h-full {
  height: 100%;
}

.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.w-full {
  width: 100% !important;
}

.z-50 {
  z-index: 50;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.leading-8 {
  line-height: 2rem;
}

.content-between {
  align-content: space-between;
}

.justify-between {
  justify-content: space-between !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}
