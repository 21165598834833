.nav-button {
  margin-left: auto;
  margin-right: 0;
}

.nav-input {
  color: #ffffff;
  background-color: #d43790;
}

.nav-input:hover {
  background-color: #d3d3d3;
}

.custom-p {
  text-align: center;
  font-size: 1.5rem !important;
}

.sd-element__title span {
  font-size: 1.5rem !important;
}
